var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"game-card",staticStyle:{"border-bottom":"solid var(--light-gray) 1px"}},[_c('div',{staticClass:"row p-0 m-0 small-text mb-0 opacity-8"},[_c('div',{staticClass:"col-9 p-0 m-0 text-dark"},[_vm._v(" "+_vm._s(_vm.fixture.country)+" • "+_vm._s(_vm.shortenName(_vm.fixture.tournament, 40))+" ")]),_c('div',{staticClass:"col-3 p-0 m-0 text-dark d-none"},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.live),expression:"!live"}]},[_vm._v(_vm._s(_vm.formatMatchDate(_vm.fixture.date)))])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.live),expression:"!live"}],staticClass:"col-3 p-0 m-0 text-dark text-right"},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.live),expression:"!live"}]},[_vm._v(_vm._s(_vm.formatMatchDate(_vm.fixture.date)))])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.live),expression:"live"}],staticClass:"col-3 p-0 m-0 text-right"},[_vm._v(" "+_vm._s(_vm.fixture.match_status)+" "),_c('span',{staticClass:"text-dark pl-1"},[_vm._v(" "+_vm._s(_vm.fixture.event_time)+" ")])])]),_c('div',{staticClass:"row p-0 m-0 games"},[_c('div',{staticClass:"col-5 px-0 text-dark game-card-teams pt-3"},[_c('router-link',{staticClass:"text-dark d-block font-weight-bold",staticStyle:{"text-decoration":"none"},attrs:{"to":{
            name: 'match',
            params: {
              game_id: _vm.fixture.game_id === undefined ? 0 : _vm.fixture.game_id,
              name: _vm.toURL(_vm.fixture.name),
              status: _vm.live ? 'live' : 'prematch',
            },
          }}},[_c('div',{staticClass:"line-height-1 mb-1 font-size-13"},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.live),expression:"live"}],staticClass:"score",domProps:{"textContent":_vm._s(_vm.fixture.home_score)}}),_vm._v(" "+_vm._s(_vm.shortenName(_vm.home_team, 20))+" ")]),_c('div',{staticClass:"line-height-1 mb-0 font-size-13"},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.live),expression:"live"}],staticClass:"score",domProps:{"textContent":_vm._s(_vm.fixture.away_score)}}),_vm._v(" "+_vm._s(_vm.shortenName(_vm.away_team, 20))+" ")])])],1),_c('div',{staticClass:"odds"},_vm._l((_vm.fixture.highlight_market.outcomes),function(o){return _c('Odd',{key:_vm.getKey(o.odd_id),staticClass:"col text-center bold",class:_vm.getClasses(o),staticStyle:{"margin":"0px !important","padding":"2px !important","height":"fit-content"},attrs:{"id":o.odd_id,"sport_id":_vm.fixture.sport_id,"match_id":_vm.fixture.match_id,"market_name":o.market_name,"home_team":_vm.home_team,"away_team":_vm.away_team,"market_id":_vm.fixture.highlight_market.market_id,"outcome_id":o.outcome_id,"specifier":_vm.fixture.highlight_market.specifier,"active":o.active,"status":_vm.fixture.highlight_market.status,"odds":o.odds,"producer_id":_vm.fixture.producer_id,"producer_status":_vm.fixture.producer_status,"previous_odds":o.previous_odds,"alias":o.alias,"probability":o.probability,"pick":o}})}),1)])]),_c('div',{staticClass:"space-1"})])
}
var staticRenderFns = []

export { render, staticRenderFns }